import React from 'react';
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  // Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Divider,
  Badge
} from '@chakra-ui/react';
import {
  MdOutlineHome,
  MdOutlineGridView,
  MdTextFormat,
  MdTitle,
  MdOutlineImage,
  MdWebAsset,
  MdPalette,
  MdOutlineSettings
} from 'react-icons/md';
import Link from 'next/link';
import Logo from '@/components/Logo';
import { useRouter } from 'next/router';

const LinkItems = [
  { name: 'Home', path: '/', icon: MdOutlineHome },
  { name: 'Preview', path: '/preview', icon: MdOutlineGridView },
  { name: 'Fonts', path: '/fonts', icon: MdTitle },
  { name: 'Colors', path: '/colors', icon: MdPalette },
  { name: 'Images', path: '/images', icon: MdOutlineImage },
  { name: 'Showcases', path: '/showcases', icon: MdWebAsset },
  { name: 'Settings', path: '/settings', icon: MdOutlineSettings }
];

const MenuSideBar = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh">
      <SidebarContent
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }}>{children}</Box>
    </Box>
  );
};

const SidebarContent = ({ onClose, ...rest }) => {
  return (
    <Box
      // bg={useColorModeValue('white', 'gray.400')}
      pt={4}
      borderRight="2px"
      borderRightColor={useColorModeValue('blackAlpha.300', 'gray.700')}
      // boxShadow="xl"
      w={{ base: 'full', md: 60 }}
      // pos="fixed"
      h="full"
      {...rest}
    >
      <Flex alignItems="center" mx="8">
        {/* <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          Logo
        </Text> */}
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem key={link.name} path={link.path} icon={link.icon}>
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

const NavItem = ({ path, icon, children, ...rest }) => {
  const router = useRouter();

  return (
    <Link
      href={path}
      // style={{ textDecoration: 'none' }}
      // _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align="center"
        p={4}
        mx={2}
        borderRadius="md"
        role="group"
        cursor="pointer"
        bg={router.pathname == path ? 'gray.200' : 'white'}
        color={router.pathname == path ? 'black' : 'black'}
        _hover={{
          bg: 'gray.100',
          color: 'black'
        }}
        {...rest}
      >
        {icon && (
          <Icon
            w={6}
            h={6}
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'black'
            }}
            as={icon}
          />
        )}
        {children}
        {children === 'Colors' || children === 'Settings' ? (
          <Badge colorScheme="green" ml={2}>
            NEW
          </Badge>
        ) : null}
      </Flex>
    </Link>
  );
};
const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="#4e3000"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue('white', 'gray.900')}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<MenuIcon />}
      />

      <Logo w="40px" color={['white', 'white', 'primary.500', 'primary.500']} />
    </Flex>
  );
};

export default MenuSideBar;
