import React from 'react';
import { Flex, Box, Divider } from '@chakra-ui/react';
import Header from '@/components/sections/Header';
import SEO from '@/components/SEO';
import MenuSideBar from '@/components/MenuSideBar';

export default function MainLayout({ children, pageMeta }) {
  return (
    <Flex direction="column">
      <SEO
        title={pageMeta.title}
        description={pageMeta.description}
        url={pageMeta.url}
        image={pageMeta.image}
        creator={pageMeta.creator}
        keywords={pageMeta.keywords}
      />
      <Header />
      <Divider />
      <Flex direction="row" flex={1}>
        {children}
      </Flex>
    </Flex>
  );
}
