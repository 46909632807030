import React from 'react';

import {
  Box,
  Link,
  Flex,
  Heading,
  Text,
  Button,
  Center,
  useToast,
  Spinner,
  IconButton,
  HStack
} from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { supabase } from '@/utils/supabase-client';
import { useUser } from '@/utils/useUser';
import { postData } from '@/utils/helpers';

import { LogSnag } from 'logsnag';
import { LOGSNAG_API_TOKEN } from '@/utils/constants';
import MenuSideBar from '@/components/MenuSideBar';
import MainLayout from '@/components/layouts/MainLayout';
import NextLink from 'next/link';
import { ExternalLinkIcon, QuestionOutlineIcon } from '@chakra-ui/icons';
import {
  MdOutlineGridView,
  MdTextFormat,
  MdLink,
  MdTitle,
  MdWebAsset
} from 'react-icons/md';

const pageMeta = {
  title: 'FontVisual | Font preview tool for crafters and designers',
  description:
    'The font preview tool you need for your personalised products. Embed on your website or share the link to your customers. Save valuable time.',
  image: 'http://app.fontvisual.com/fontvisualcover.jpg',
  keywords:
    'font preview tool for website, font preview tool for shopify, live font preview, font preview online, wordpress fonts preview, font preview generator, craft fonts'
};

const HomePage = () => {
  const router = useRouter();
  const [isRecovery, setIsRecovery] = useState(false);
  const [authenticatedState, setAuthenticatedState] =
    useState('not-authenticated');

  const toast = useToast();
  const { planId, mode } = router.query;
  // console.log('Home planId ' + planId);
  // console.log('Home mode ' + mode);
  // console.log('Home authenticatedState ' + authenticatedState);
  const { user, session } = useUser();
  // console.log('Home user ', user);
  // console.log('Home session ', session);

  useEffect(() => {
    if (authenticatedState == 'authenticated') {
      if (planId && planId !== 'undefined') {
        // && mode == 'trial') {
        handleTrial(planId);
      } else {
        router.push('/');
      }
    }
  }, [user]);

  const handleTrial = async (planId) => {
    if (user) {
      const { data, error } = await postData({
        url: '/api/startTrial',
        token: session.access_token,
        data: { planId: planId }
      });
      // console.log('handleTrial', data);
      if (error) {
        toast({
          title: 'Error',
          description: 'An error occurred. Please contact support.',
          status: 'error',
          duration: 3000,
          isClosable: true
        });
      } else {
        toast({
          title: 'Success!',
          description:
            'Your 7-day trial of FontVisual Plus plan has now started.',
          status: 'success',
          duration: 3000,
          isClosable: true
        });
        const logsnag = new LogSnag(LOGSNAG_API_TOKEN);
        await logsnag.publish({
          project: 'fontvisual',
          channel: 'trial',
          event: 'New Customer Trial Started! 🔥',
          description: `email: ${user.email}`,
          icon: '💵',
          notify: true
        });

        router.push('/');
      }
    } else {
      router.push({
        pathname: '/signup',
        query: { planId: planId, isTrial: true }
      });
    }
  };

  useEffect(() => {
    // router.push('/signin');
    if (!user) {
      router.push('/signin');
    }

    /* fires when a user signs in or out */
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        // console.log('onAuthStateChange', event);
        // console.log('onAuthStateChange', session);
        handleAuthChange(event, session);
        if (event === 'PASSWORD_RECOVERY') {
          setIsRecovery(true);
        } else if (event === 'USER_UPDATED') {
          setAuthenticatedState('authenticated');
        } else if (event === 'SIGNED_IN') {
          setAuthenticatedState('authenticated');
          // toast({
          //   title: 'Account confirmed',
          //   description: 'Welcome to FontVisual!',
          //   status: 'success',
          //   duration: 3000,
          //   isClosable: true
          // });
        }
        // if (event === 'SIGNED_OUT') {
        //   setAuthenticatedState('not-authenticated');
        // }
      }
    );
    return () => {
      authListener.unsubscribe();
    };
  }, []);

  async function handleAuthChange(event, session) {
    /* sets and removes the Supabase cookie */
    await fetch('/api/auth', {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      credentials: 'same-origin',
      body: JSON.stringify({ event, session })
    });
  }

  return (
    <>
      {user ? (
        <Flex flexDirection="column" pt={4} px={8} w="100%">
          <Heading as="h1" mb="8" size="lg" color="primary.800">
            Welcome to your FontVisual dashboard!
          </Heading>
          <Heading as="h2" size="md" mb="4" color="primary.800">
            Get Started
          </Heading>
          <NextLink href="/fonts" passHref>
            <HStack mb="4" cursor="pointer">
              <IconButton aria-label="Create showcase" icon={<MdTitle />} />
              <Heading as="h4" size="sm" mb="4" fontWeight="normal">
                Start uploading your own fonts
              </Heading>
            </HStack>
          </NextLink>

          <NextLink href="/showcases" passHref>
            <HStack mb="8" cursor="pointer">
              <IconButton aria-label="Create showcase" icon={<MdWebAsset />} />
              <Heading as="h4" size="sm" mb="4" fontWeight="normal">
                Need a font preview for your products? Create a showcase
              </Heading>
            </HStack>
          </NextLink>

          <Heading as="h2" size="md" mb="4" color="primary.800">
            Resources
          </Heading>
          <Link href="https://fontvisual.com/help" isExternal>
            <HStack mb="4" cursor="pointer">
              <IconButton
                aria-label="FontVisual Help Center"
                icon={<QuestionOutlineIcon />}
              />
              <Heading as="h4" size="sm" fontWeight="normal">
                Check out our Help Center
              </Heading>
            </HStack>
          </Link>
        </Flex>
      ) : (
        <Center marginTop={20}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="primary.300"
            size="xl"
          />
        </Center>
      )}
    </>
  );
};

HomePage.getLayout = function getLayout(page) {
  const router = useRouter();
  const { user } = useUser();
  if (user) {
    return (
      <MainLayout pageMeta={pageMeta}>
        <MenuSideBar />
        {page}
      </MainLayout>
    );
  }

  return <>{page}</>;

  // return (
  //   <Center marginTop={20}>
  //     <Spinner
  //       thickness="4px"
  //       speed="0.65s"
  //       emptyColor="gray.200"
  //       color="primary.300"
  //       size="xl"
  //     />
  //   </Center>
  // );
};

export default HomePage;
